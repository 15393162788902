.dashboard h2 {
	font-family: "MedievalSharp", cursive;
	font-weight: 600;
	font-style: normal;
	font-size: 1.8rem;
	text-align: center;
	color: #11103e;
	margin: 0;
}

.dashboard .subheading {
	margin: 0 0 3rem 0;
	text-align: center;
}
.dashboard .subheading svg {
	width: 1rem;
	height: 1rem;
}

.dashboard-show-options {
	display: flex;
	width: 100%;
	max-width: min(740px, 100vw);
	margin-left: 30px;
}

.navigate-head .show-subscription {
	display: block;
	flex: 1;
	font-family: "MedievalSharp", cursive;
}
.navigate-head .show-subscription img
{
	margin: 0.2rem;
	height: 1rem;
	width: auto;
	float: left;
}
.navigate-head .show-subscription p {
	position: relative;
	margin: 0.2rem 0 0 0;
	float: left;
	font-size: 1rem;
}
.navigate-head .show-subscription small {
	font-size: 0.7rem;
}
.dashboard-buttons-container {
	flex: 1;
}
.dashboard-buttons {
	display: flex;
	gap: 0.7rem;
	float: right;
}
.dashboard-buttons svg {
	width: 1.5rem;
	height: 1.5rem;
	color: #545454;
	cursor: pointer;
}

/* Notifications */
.dashboard .notifications {
	position: relative;
	cursor: pointer;
}
.dashboard .notifications .notification-count {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	left: -0.2rem;
	bottom: -0.2rem;
	min-width: 1rem;
	height: 1rem;
	border-radius: 50%;
	border: 0.05em solid white;
	font-size: 0.8rem;
	color: white;
	background-color: #fa3e3e;
	font-weight: bold;
}

.dashboard-notifications .contexify_itemContent {
	color: #6b6b6b !important;
}
.dashboard-notifications .contexify_itemContent:hover {
	color: black !important;
	background-color: white !important;
}

/* Spotlight & Feed */

.dashboard .spotlight {
	background-color: #edf4ff;
	border-radius: 12px;
	padding: 16px;
	border: 1px solid #d0e1ff;
}

.dashboard .feed h2 svg {
	width: 0.9rem;
	height: 0.9rem;
	margin: 0 0 0.8rem 0.5rem;
	cursor: pointer;
}

.dashboard .feedback {
	padding: 0 30px;
	font-size: 1rem;
}

/* Topics */

.topics-section {
	margin-bottom: 24px;
}
.topics-section .edit-button svg {
	width: 1rem;
	height: 1rem;
	margin-right: 0.3rem;
}

.topics-title-container {
	display: flex;
	justify-content: space-between;
}
.topics-title {
	margin: 0;
}

.edit-button {
	display: flex;
	align-items: center;
	background-color: #f0f0f0;
	border: none;
	border-radius: 9999px;
	padding: 8px 12px;
	font-size: 14px;
	color: #333;
	cursor: pointer;
}

.edit-button:hover {
	background-color: #e0e0e0;
}

.topics-container {
	display: flex;
	flex-wrap: wrap;
	margin-top: 12px;
}

.remove-topic {
	background: none;
	border: none;
	cursor: pointer;
}

.topic-pill {
	display: flex;
	align-items: center;
	background-color: #e6f0ff;
	color: #3366cc;
	padding: 4px 12px;
	border-radius: 9999px;
	font-size: 14px;
}
.remove-fiction .topic-pill {
	background-color: #e5ccff;
	color: #7b00ff;
}
.topic-pill svg.lucide-x {
	width: 0.6rem;
	height: 0.6rem;
	margin-left: 0.3rem;
}

.empty-topics {
	width: 100%;
	text-align: center;
	padding: 16px;
	background-color: #f7f7f7;
	border-radius: 8px;
}

.topics-section .active-settings {
	background-color: #eff6ff;
	border-radius: 6px;
	padding: 8px 12px;
	margin: 0.8rem 0 1rem 0;
	font-size: 0.88rem;
}
.topics-section .active-settings button {
	background: none;
	border: none;
	padding: 0;
	color: #3366cc;
	cursor: pointer;
}

.find-topics-button {
	margin-top: 8px;
	background: none;
	border: none;
	color: #3366cc;
	cursor: pointer;
}

.find-topics-button:hover {
	text-decoration: underline;
}

/* Topic picker */

.overlay {
	position: fixed;
	display: none;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: #cccccc99;
	cursor: pointer;
}
.overlay.show {
	display: block;
}

.topic-picker {
	position: fixed;
	display: none;
	padding: 0;
	top: 10vh;
	left: 50%;
	transform: translate(-50%, 0);
	border-radius: 0.75rem;
	border: 1px solid #e5e7eb;
	background-color: #ffffff;
	width: min(90vw, 400px);
	z-index: 99;
	max-height: 80vh;
	overflow: visible;
	box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.topic-picker.show {
	display: flex;
	flex-direction: column;
}

.topic-picker .close {
	position: absolute;
	right: -10px;
	top: -10px;
	width: 2rem;
	height: 2rem;
	fill: #ffff;
	cursor: pointer;
}

.topic-picker .close:hover {
	color: #374151;
}

/* Tabs styling */
.tabs-container {
	display: flex;
	background-color: #f9fafb;
	border-bottom: 1px solid #e5e7eb;
}

.tab-button {
	padding: 0.75rem;
	background: none;
	border: none;
	cursor: pointer;
	font-weight: 500;
	font-size: 14px;
	color: #6b7280;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.25rem;
	border-bottom: 2px solid transparent;
}

.tab-button svg {
	width: 1.1rem;
	height: 1.1rem;
}

.tab-button span {
	font-size: 12px;
}

.tab-button:hover {
	color: #4b5563;
	background-color: rgba(0, 0, 0, 0.02);
}

.tab-button.active {
	color: #3b82f6;
	border-bottom: 2px solid #3b82f6;
	background-color: white;
}

.tab-content {
	max-height: calc(80vh - 58px);
	overflow-y: auto;
	overflow-x: hidden;
	padding: 1rem;
	padding-top: 0.75rem;
}

/* Topic styles */
.topic-picker .topic-section {
	margin-bottom: 1rem;
}

.topic-picker .section-title,
.tags-title,
.filter-title {
	font-size: 16px;
	font-weight: 600;
	color: #1f2937;
	margin: 0 0 0.5rem 0;
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.filter-title svg {
	width: 1rem;
	height: 1rem;
}

.topic-picker .topic-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 0.5rem;
	margin-top: 0.5rem;
}

.topic-button {
	padding: 0.3rem 0.75rem;
	border-radius: 0.5rem;
	text-align: left;
	transition: all 0.2s;
	background-color: #f3f4f6;
	border: 1px solid transparent;
	cursor: pointer;
}

.topic-button:hover {
	background-color: #e6f7ff;
}

.topic-button-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.topic-button-content svg {
	color: #3b82f6;
	width: 1rem;
	height: 1rem;
}

.nonfiction-buttons .topic-button-content svg {
	color: #3b82f6;
}

.fiction-buttons .topic-button-content svg {
	color: #8b5cf6;
}

.topic-name {
	font-weight: 500;
	font-size: 14px;
}

.nonfiction-buttons .topic-button.selected {
	background-color: #dbeafe;
	border-color: #3b82f6;
	color: #1e40af;
}

.fiction-buttons .topic-button.selected {
	background-color: #f3e8ff;
	border-color: #8b5cf6;
	color: #6d28d9;
}

/* Tags tab styling */
.search-container {
	position: relative;
	margin-bottom: 1rem;
}

.search-container svg {
	width: 1.1rem;
	height: 1.1rem;
}

.tag-search-input {
	width: calc(100% - 3.25rem);
	padding: 0.75rem;
	padding-left: 2.5rem;
	border-radius: 2rem;
	border: 1px solid #e5e7eb;
	font-size: 14px;
	background-color: #f9fafb;
}

.tag-search-input:focus {
	outline: none;
	border-color: #3b82f6;
	background-color: white;
}

.search-icon {
	position: absolute;
	left: 0.75rem;
	top: 50%;
	transform: translateY(-50%);
	color: #9ca3af;
}

.selected-tags-section {
	margin-bottom: 1.25rem;
}

.selected-tags {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
}

.popular-tags {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 0.5rem;
	max-height: calc(80vh - 12rem);
	overflow-y: auto;
}

.tag-button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem 0.75rem;
	border-radius: 0.5rem;
	background-color: #f3f4f6;
	border: 1px solid transparent;
	cursor: pointer;
	transition: all 0.2s;
}

.tag-button:hover {
	background-color: #e6f7ff;
	border-color: #bfdbfe;
}

.tag-button.selected {
	background-color: #dbeafe;
	border-color: #3b82f6;
	color: #1e40af;
}

.tag-count {
	font-size: 13px;
	color: #6b7280;
}

/* Filters tab styling */
.filter-group {
	margin-bottom: 1.25rem;
}

.filter-group .options {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	margin-bottom: 0.5rem;
}

.filter-group .options .option {
	padding: 0.4rem 0.75rem;
	background-color: #f3f4f6;
	border: 1px solid transparent;
	border-radius: 2rem;
	font-size: 13px;
	cursor: pointer;
	transition: all 0.2s;
}

.filter-group .options .option:hover {
	background-color: #e6f7ff;
}

.filter-group .options .option.selected {
	background-color: #dbeafe;
	border-color: #3b82f6;
	color: #1e40af;
}

/* Sort tab styling */
.sort-options {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.sort-option {
	padding: 0.75rem;
	background-color: #f3f4f6;
	border: 1px solid transparent;
	border-radius: 0.5rem;
	text-align: left;
	font-size: 14px;
	cursor: pointer;
	transition: all 0.2s;
}

.sort-option:hover {
	background-color: #e6f7ff;
}

.sort-option.selected {
	background-color: #dbeafe;
	border-color: #3b82f6;
	color: #1e40af;
}

/* NSFW container */
.nsfw-container {
	margin-top: 1rem;
	padding-top: 0.75rem;
	border-top: 1px solid #e5e7eb;
	display: flex;
	align-items: center;
}

.nsfw-checkbox {
	margin-right: 0.5rem;
	width: 16px;
	height: 16px;
	accent-color: #3b82f6;
	cursor: pointer;
}

.nsfw-label {
	font-size: 14px;
	color: #4b5563;
	cursor: pointer;
}

@media (max-width:600px) {
	.dashboard .view-all-container {
		display: block;
	}
}
