.time-wheel-container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1.5rem;
}

.time-wheel-container .time-unit-selector {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
}

.time-wheel-container .time-unit-label {
	display: block;
	margin-bottom: 0.5rem;
	font-size: 1.125rem;
	color: #333;
}

.time-wheel-container .wheel-control {
	position: relative;
	width: 5rem;
	height: 4.5rem;
	background-color: #f0f5ff;
	border-radius: 0.5rem;
	box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	user-select: none;
	cursor: pointer;
	overflow: hidden;
}

.time-wheel-container .wheel-control-adjacent {
	position: absolute;
	color: #9ca3af;
	opacity: 0.5;
	font-size: 1.125rem;
}

.time-wheel-container .wheel-control-adjacent-top {
	top: 0.25rem;
}

.time-wheel-container .wheel-control-adjacent-bottom {
	bottom: -0.5rem;
}

.time-wheel-container .wheel-control-current {
	font-size: 1.875rem;
	font-weight: 700;
	color: #2563eb;
	margin-top: 1rem;
}

.time-wheel-container .wheel-control-gradient {
	position: absolute;
	inset: 0;
	pointer-events: none;
}

.time-wheel-container .wheel-control-gradient-top {
	background: linear-gradient(to bottom, white, transparent);
	top: 0;
	left: 0;
	right: 0;
	height: 50%;
}

.time-wheel-container .wheel-control-gradient-bottom {
	background: linear-gradient(to top, white, transparent);
	bottom: 0;
	left: 0;
	right: 0;
	height: 50%;
}

.time-wheel-container .wheel-button {
	width: 3rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #e5e7eb;
	background-color: #ffffff;
	border-radius: 0.25rem;
	cursor: pointer;
	transition: background-color 0.2s, border-color 0.2s;
}

.time-wheel-container .wheel-button:hover {
	background-color: #f9fafb;
	border-color: #d1d5db;
}

.time-wheel-container .time-separator {
	font-size: 2.5rem;
	color: #d1d5db;
	align-self: center;
	padding-bottom: 1rem;
}

.time-wheel-container .duration-display {
	margin-left: 1.5rem;
	font-size: 0.875rem;
	color: #6b7280;
}
